.login-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo {
    width: 156px;
    height: 128px;
    margin-bottom: 10px;
}

.input-centered-round {
    border-radius: 20px;
    width: 100%;
}

.signin {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
    background: linear-gradient(253.58deg, theme('colors.primary') 1.55%, #007cff 95.8%);
}

.google {
    background-color: #ffffff;
    /* color: #737373; */
    border-color: #737373;
    font-weight: 600;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    letter-spacing: 0.04em;
    padding: 0 16px;
    width: 100%;
    max-width: 340px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
