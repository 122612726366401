/* Sidebar menu */
.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: theme('colors.primary') !important;
}

.ant-menu-item {
    padding-block: 8px;
    display: flex !important;
    align-items: flex-start !important;
    height: auto !important;
}

.ant-menu-item-selected {
    color: theme('colors.primary') !important;
}

.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: visible !important;
    text-overflow: unset !important ;
    white-space: normal !important;
    line-height: 16px;
}

.ant-menu-inline {
    border: none !important;
}

.ant-btn-primary {
    background-color: theme('colors.primary');
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
    font-weight: 500;
}

.ant-menu-submenu-open .ant-menu-sub .ant-menu-item-selected .ant-menu-title-content {
    font-weight: 500;
}

/* tabs */
.ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 400;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    font-weight: 500;
}

.ant-tabs-ink-bar {
    background-color: white !important;
}

/* layout */
.course-layout {
    height: calc(100vh - 100px);
    max-width: 1920px;
    margin-inline: auto;
    width: 100%;
}

.course-content {
    width: calc(100vw - 250px) !important;
    overflow-y: auto;
    background-color: white;
    position: relative;
    width: 100%;
}

/* Markdown Styling */
.markdown-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 72px;
    /* min-height: 450px; */
}

.markdown-wrapper img {
    max-width: 100%;
    height: auto;
    margin-top: 36px;
    margin-bottom: 36px;
}

.markdown-wrapper pre {
    background-color: #1e2a37;
    width: 100%;
    line-height: 24px;
    font-family: monospace;
    white-space: pre;
    border-radius: 4px;
    padding: 36px;
    color: white;
}

.markdown-wrapper h1,
.markdown-wrapper h2,
.markdown-wrapper h3,
.markdown-wrappere h4,
.markdown-wrapper h5,
.markdown-wrapper h6 {
    font-weight: bold;
    margin-top: 1.5rem;
}

.markdown-wrapper h2 {
    font-size: 24px;
}

.markdown-wrapper p {
    line-height: 24px;
    margin-bottom: 1rem;
    font-size: 20px;
}

.prose ul,
.prose ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}
.markdown-wrapper ul {
    list-style-type: square;
    padding-left: 36px;
}

.markdown-wrapper ul li,
.markdown-wrapper ol li {
    margin-bottom: 0.5rem;
    font-size: 16px;
}

.markdown-wrapper blockquote {
    border-left: 2px solid #333;
    padding-left: 1rem;
    margin: 1rem 0;
    font-style: italic;
}

.markdown-wrapper a {
    color: #007bff;
    text-decoration: underline;
}

.markdown-wrapper a:hover {
    text-decoration: none;
}

/* Mobile Menu */
.mobile-menu {
    width: 60vw;
    max-height: calc(100vh - 100px);
    position: absolute;
    z-index: 100;
}

.mobile-menu-overlay {
    width: 40vw;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    right: 0px;
    z-index: 100;
    height: calc(100vh - 100px);
}

.no-scroll {
    overflow: hidden !important;
}

@media (max-width: 576px) {
    .course-content {
        width: calc(100vw) !important;
    }

    /* countdown */
    .ant-statistic-content-value {
        font-size: 16px !important;
    }
}
