@keyframes loadingAnimation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.custom-loader {
    background: linear-gradient(90deg, #888 25%, #444 50%, #888 75%);
    background-size: 200% 100%;
    animation: loadingAnimation 3s linear infinite;
    opacity: 0.2;
    margin-bottom: 16px;
}
