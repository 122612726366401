.manager-console .ant-select {
    height: 42px;
}

.manager-console .ant-select-selector {
    padding: 8px !important;
    height: 100% !important;
}

.manager-console .ant-select-selection-item {
    margin-top: -2px !important;
}

.manager-console table {
    width: 100%;
    border-collapse: collapse;
}

.manager-console th,
.manager-console td {
    padding-inline: 16px;
    padding-block: 8px;
    text-align: end;
}

.manager-console th {
    border-right: 1px solid theme('colors.primary');
}

.manager-console th:first-child {
    text-align: start;
}

.manager-console td:first-child {
    text-align: start;
    min-width: 240px;
}

.manager-console td {
    border: 1px solid lightgray;
}

.manager-console .grades-detailed td {
    min-width: 180px;
}

.manager-console .grades-detailed td:first-child {
    min-width: 240px;
}

.manager-console .ant-form-item-explain-error {
    font-size: 12px;
    margin-left: 12px;
    margin-block: 8px;
}

.manager-console .select-overflow {
    overflow: hidden;
}

.manager-console .select-overflow .ant-select-selection-item {
    margin-bottom: 12px !important;
}

.manager-console .multi-filter .ant-select-arrow,
.manager-console .multi-filter .ant-select-clear {
    margin-right: 16px;
}

.grades-detailed th,
.grades-detailed td {
    padding: 8px;
    text-align: left;
}

.grades-detailed th:first-child,
.grades-detailed td:first-child {
    position: sticky;
    left: 0;
    z-index: 1000;
    background-color: #efefef;
}

#th-name {
    background-color: theme('colors.primary');
}

#th-spacer {
    background-color: rgb(187, 187, 187);
}

.grades-detailed thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: theme('colors.primary');
}

.grades-detailed tbody th:first-child,
.grades-detailed tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
}
