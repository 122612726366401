.medium-logo {
    width: 120px;
    height: 96px;
    margin-bottom: 10px;
}

.agree {
    text-align: center;
    color: #ffff;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
}

.disagree {
    text-align: center;
    width: 100%;
    color: #ffff;
    margin-bottom: 10px;
    font-weight: 600;
    background: #5c5c5c;
    /* background: theme('colors.primary'); */
}

.instructions-ul {
    list-style-type: disc; /* Style of the bullet points */
    padding-left: 20px;
}

.instructions-li {
    margin-bottom: 5px; /* Spacing between list items */
}

.indexSelected {
    border: 2px solid theme('colors.primary');
    color: theme('colors.primary');
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.04);
}

.align-right {
    text-align: right;
}
