.quiz table {
    width: 100%;
    max-width: 500px;
    border-collapse: collapse;
}

.quiz th,
.quiz td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.quiz thead {
    font-weight: 700;
    background-color: gray;
    color: white;
}

.quiz th {
    width: auto; /* Let the width be determined by the content */
}
